import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { ExternalAnchor } from 'components/Anchor';
import ContainerHeader from 'components/ContainerHeader';
import {
  TableComponent as Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'components/Table';
import Text from 'components/Text';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';
import { is_cellphone } from 'pages/app/Users';
import { TitleContainer } from 'pages/track/Contact/style';
import { formatPhoneNumber, formatTimestampEST } from 'shared/formatters';

const MainContainer = styled.div`
  padding: 10px 30px;
`;

const MemberDetail = ({ transaction }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <MainContainer>
      <ContainerHeader borderWidth={'0px'} style={{ paddingLeft: 0 }}>
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            Transaction Details
          </Text>
        </TitleContainer>
      </ContainerHeader>
      {!isMobile ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" padding={'10px'}>
                Purchaser / Order #
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Purchase Date / Time
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Order Source
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Payment Method
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Sold By
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Transfer
              </TableCell>
              <TableCell scope="col" padding={'10px'}>
                Charge
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell padding={'10px'}>
                {transaction?.user?.formatted_name
                  ? is_cellphone(transaction?.user?.formatted_name)
                    ? formatPhoneNumber(transaction?.user?.formatted_name)
                    : transaction?.user?.formatted_name
                  : transaction.id}
              </TableCell>
              <TableCell padding={'10px'}>
                {formatTimestampEST(Number(transaction.purchase_date))}
              </TableCell>

              <TableCell padding={'10px'}>
                {transaction.order_source.toUpperCase()}
              </TableCell>
              <TableCell padding={'10px'}>
                {transaction.payment_method.toUpperCase()}
              </TableCell>
              <TableCell padding={'10px'}>
                {transaction.register ? transaction.register : 'N/A'}
              </TableCell>
              <TableCell padding={'10px'}>
                {transaction.transfer_id &&
                !transaction.transfer_id.includes('null') ? (
                  <ExternalAnchor
                    href={transaction.transfer_id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </ExternalAnchor>
                ) : (
                  'N/A'
                )}
              </TableCell>
              <TableCell padding={'10px'}>
                {transaction.charge_id &&
                !transaction.charge_id.includes('null') ? (
                  <ExternalAnchor
                    href={transaction.charge_id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </ExternalAnchor>
                ) : (
                  'N/A'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Purchaser:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction?.user?.formatted_name || 'Anonymous'}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Purchase Date / Time:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {formatTimestampEST(Number(transaction.purchase_date))}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Order Source:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.order_source.toUpperCase()}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Payment Method:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.payment_method.toUpperCase()}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Sold By:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.register ? transaction.register : 'N/A'}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Transfer:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.transfer_id &&
              !transaction.transfer_id.includes('null') ? (
                <ExternalAnchor
                  href={transaction.transfer_id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </ExternalAnchor>
              ) : (
                'N/A'
              )}
            </Text>
          </ResponsiveCol>
          <ResponsiveCol>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Charge:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              {transaction.charge_id &&
              !transaction.charge_id.includes('null') ? (
                <ExternalAnchor
                  href={transaction.charge_id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </ExternalAnchor>
              ) : (
                'N/A'
              )}
            </Text>
          </ResponsiveCol>
        </>
      )}
    </MainContainer>
  );
};

export default MemberDetail;
